@-webkit-keyframes rotation {
    25% {
        -webkit-transform: rotate(8deg);
                transform: rotate(8deg)
    }
    50% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    75% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg)
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }

  @keyframes rotation {
    25% {
        -webkit-transform: rotate(8deg);
                transform: rotate(8deg)
    }
    50% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    75% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg)
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }

  .parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .background-image {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
  }
  .overlay-image {
    position: absolute;
    -webkit-transform: scale(2);
            transform: scale(2);
    top: -15%;
    left: 30%;
    height: 100%;
  }

  @media only screen and (max-height: 570px) {
    .overlay-image {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }

/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
    --ion-color-primary: #fe6410;
    --ion-color-primary-rgb: 254, 100, 16;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0580e;
    --ion-color-primary-tint: #fe7428;

    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
    --ion-color-base: #69bb7b;
    --ion-color-base-rgb: 105, 187, 123;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #5ca56c;
    --ion-color-tint: #78c288;
}

.ion-color-twitter {
    --ion-color-base: #1da1f4;
    --ion-color-base-rgb: 29, 161, 244;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #1a8ed7;
    --ion-color-tint: #34aaf5;
}

.ion-color-google {
    --ion-color-base: #dc4a38;
    --ion-color-base-rgb: 220, 74, 56;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #c24131;
    --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
    --ion-color-base: #23b6ea;
    --ion-color-base-rgb: 35, 182, 234;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #1fa0ce;
    --ion-color-tint: #39bdec;
}

.ion-color-facebook {
    --ion-color-base: #3b5998;
    --ion-color-base-rgb: 59, 89, 152;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #344e86;
    --ion-color-tint: #4f6aa2;
}

.ion-color-icon {
    --ion-color-base: #e0e0e0;
}

ion-refresher ion-refresher-content {
    background: #f8f8f8;
   --color:#fe7428!important;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
    --ion-headings-font-weight: 300;

    --ion-color-angular: #ac282b;
    --ion-color-communication: #8e8d93;
    --ion-color-tooling: #fe4c52;
    --ion-color-services: #fd8b2d;
    --ion-color-design: #fed035;
    --ion-color-workshop: #69bb7b;
    --ion-color-food: #3bc7c4;
    --ion-color-documentation: #b16be3;
    --ion-color-navigation: #6600cc;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
}

* {
    font-family: "Source Sans Pro";
}

ion-toolbar {
    border-bottom: thin solid #fe6410;
    border-bottom: thin solid var(--ion-color-primary);
}

ion-item-divider {
    border: none;
    padding: 0 13px;
    background: var(--ion-background-color);
}

ion-item-divider:first-child {
    margin-top: 1.55em;
}

ion-content > * {
    max-width: 1502px;
}

ion-col{
    padding: 0px;
}

ion-toast{
    margin-top: 60px;
}

.app {
    background: "#f8f8f8"
}

.card-list {
    display: grid;
    align-items: start;
    grid-gap: 0px 21px;
    padding: 8px 17px 17px 17px;
}

.ion-padding-top:last-child {
    margin-left: auto;
}

.split-pane-md.split-pane-visible > .split-pane-side {
    width: 338px;
    max-width: 338px;
}

.semi-bold {
    font-weight: 600;
}

.light {
    font-weight: 300;
}

.title {
    font-size: 1.3em;
}

.subtitle {
    font-size: 1em;
}

.no-transform {
    text-transform: none;
}

.speaker-header {
    display: flex;
    align-items: center;
}

.speaker-header .title {
    flex-grow: 1;
    margin-left: 1em;
}

.category-block {
    padding: 0.5em;
    color: #ffffff;
    border-radius: 2px;
    text-transform: capitalize;
    white-space: nowrap;
}

.category-general {
    background-color: #cd5000;
}

.category-design {
    background-color: #5aa4a2;
}

.card-space-between {
    justify-content: space-between;
}

.speaker-title {
    margin-left: -2.5em;
}

.speaker-title * {
    margin-left: 2.5em;
    margin-bottom: 2em;
}

.ion-header {
    width: 100%;
}

.center-content-vertically {
    display: "flex";
    justify-content: "center";
    flex-direction: "column";
}

.card-padding {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.location-chip {
    text-transform: capitalize;
    margin: 0;
}

.seperator {
    clear: both;
    display: block;
    width: 100%;
    height: 1px;
    border-top: 0.3px solid #8a8a8a;
    margin-bottom: 15px;
}

.bottom-arrow {
    height: 2em;
    position: absolute;
    bottom: -1em;
    width: 100%;
}

.notification-card {
    margin: 18px 17px;
    overflow: visible;
    border: 0.5px solid #b1b1b1;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.lecture-card {
    margin: 4px 12px 18px 12px;
    overflow: visible;
    border: 0.5px solid #b1b1b1;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.speaker-card {
    margin: 0px 0px 21px;
    overflow: visible;
    box-sizing: border-box;
    border: 0.5px solid #b1b1b1;

    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.spinner-center{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.find-lecture-card {
    grid-column: 1/4;
}
.find-lecture-card a {
    text-decoration: none;
}

.time-seperator {
    font-size: 1.5em;
    width: 100%;
    text-align: center;
    border-bottom: 0.2px solid #000000;
    line-height: 0.1em;
    margin: .5em 0;
}

.time-seperator span {
    background: #f8f8f8;
    padding: 0 10px;
}

/* headlines with lines */
.timeSlotHeader{
    overflow: hidden;
    text-align: center;
}
.timeSlotHeader > span{
   position: relative;
   display: inline-flex;
   align-items: center;
}
.timeSlotHeader > span:before, .timeSlotHeader > span:after{
   content: '';
   position: absolute;
   top: 50%;
   border-bottom: 1px solid;
   width: 591px; /* half of limiter*/
   margin: 0px 10px;
}
.timeSlotHeader > span:before{
   right: 100%;
}
.timeSlotHeader > span:after{
   left: 100%;
}

.search {
    padding: 11px 5px 0px 5px;
}

.safe-zone {
    margin-top: 20px; /* Status bar height on iOS 10 */
    margin-top: constant(
        safe-area-inset-top
    ); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}

.right {
    float: right;
}

/* Find a better way to implement these adjustments.  */
.filter-label {
    font-size: 18px !important;
    margin-left: 15px !important;
}

.tag {
    background: #f8f8f8;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 10px;
    padding: 0 10px;
}

.category {
    color: white;
    font-weight: bold;
    padding: 3px 10px;
    margin-top: 10px;
    border-radius: 3px;
}

.category-color-1{
    background: #cd5000;
}

.category-color-2{
    background: #fbbd2d;
}

.category-color-3{
    background: #d4e262;
}

.category-color-4{
    background: #5aa4a2;
}

.category-color-5{
    background: #a4d5eb;
}

.category-color-6{
    background: #377599;
}

.category-color-7{
    background: #0d3c55;
}

.ion-button-icon {
    --padding-start: 0;
    --padding-end: 0;
}

.content-background-color {
    --ion-background-color: #f8f8f8;
}

.card-background-color {
    background-color: white;
}

.time-separator-background {
    background-color: #f8f8f8;
}

.speaker-description {
    white-space: pre-wrap;
    margin-bottom: 1.1em;
}

.speaker-time-separator {
    font-size: 1rem;
}

.speaker-time-separator * {
    background-color: white !important;
}

.selected {
    --ion-background-color: #f8f8f8;
}

.menu-item-text {
    font-size: 20px !important;
}
.menu-icon-size {
    font-size: 40px !important;
}

.split-pane {
    --border: 0px;
}

.menu-list {
    padding-top: 0px;
    padding-bottom: 0px;
}

.bold {
    font-weight: bold;
}

.img-homepage-desktop {
    display: none;
}

.img-homepage-phone {
    display: block;
}

/* Makes room for search bar */
.nav-bar ion-title {
    flex-grow: 0;
}

/* Ensures proper alignment of search bar */
.nav-bar ion-searchbar {
    padding-bottom: 0;
}

@media only screen and (min-width: 480px) {
    .img-homepage-phone {
        display: none;
    }
    .img-homepage-desktop {
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    ion-tab-bar {
        display: none;
    }
    .card-list {
        padding: 13px 61px;
    }
    ion-item-divider {
        padding: 0 61px;
    }
    ion-toast {
        margin-left: 15%;
    }
}

@media only screen and (min-width: 1109px) {
    .card-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1502px) {
    .card-list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

