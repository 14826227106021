@keyframes rotation {
    25% {
        transform: rotate(8deg)
    }
    50% {
        transform: rotate(0deg)
    }
    75% {
        transform: rotate(-8deg)
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .background-image {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
  }
  .overlay-image {
    position: absolute;
    transform: scale(2);
    top: -15%;
    left: 30%;
    height: 100%;
  }

  @media only screen and (max-height: 570px) {
    .overlay-image {
      transform: scale(1.5);
    }
  }